<template>
  <div id="app">
    <VueNavigation />
    <VueLogo />
    <router-view />
    <BackToTop />
  </div>
</template>

<script>
import { createRouter, createWebHistory } from 'vue-router'
import VueHome from './views/VueHome.vue'
import VueContact from './views/VueContact.vue'
import VueNavigation from './components/VueNavigation.vue'
import VueLogo from './components/VueLogo.vue'
import BackToTop from './components/BackToTop.vue'
import IndustryInsight from './views/IndustryInsight.vue'

const routes = [
  { path: '/', component: VueHome },
  { path: '/contact', component: VueContact },
  { path: '/IndustryInsight', component: IndustryInsight },
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default {
  name: 'App',
  components: {
    VueNavigation, BackToTop, VueLogo
  },
  router,
  mounted() {
    const bootstrapLink = document.createElement('link')
    bootstrapLink.rel = 'stylesheet'
    bootstrapLink.href = 'https://maxcdn.bootstrapcdn.com/bootstrap/3.4.1/css/bootstrap.min.css'
    document.head.appendChild(bootstrapLink)

    const fontawesomeLink = document.createElement('link')
    fontawesomeLink.rel = 'stylesheet'
    fontawesomeLink.href = 'https://use.fontawesome.com/releases/v5.7.2/css/all.css'
    document.head.appendChild(fontawesomeLink)


  }
}
</script>

<style>
body {
  color: #b5b5b5;
  overflow-x: hidden;
}

#app {
  background-color: #f2f2f2;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

p,
li,
label,
input {
  color: #333300;
}

h1,
h2,
h3,
h4,
h5 {
  font-weight: 700;
  color: hsl(0, 6%, 10%);
}

html {
  overflow-x: hidden;
}

img {
  max-width: 100%;
}
</style>