<template>
  <div id="nav-container" class="navbar-fixed-top">
    <nav class="navbar">
      <!-- Navigation brand -->
      <div class="navbar-header">
        <router-link to="/" class="navbar-brand">
        </router-link>
        <img src="@/assets/AgriViewKynetec.jpg" alt="logo" width="320" height="70" />
      </div>
      <!-- Navigation links -->
      <div class="collapse navbar-collapse">
        <ul class="nav navbar-nav">
          <li>
            <router-link to="/industry_insight" data-toggle="collapse" data-target=".navbar-collapse.in">
              INDUSTRY INSIGHTS
            </router-link>
          </li>
          <li>
            <router-link to="/contact" data-toggle="collapse" data-target=".navbar-collapse.in">
              CONTACT
            </router-link>
          </li>
          <li>
            <router-link to="/" data-toggle="collapse" data-target=".navbar-collapse.in">
              HOME
            </router-link>
          </li>
        </ul>
      </div>
    </nav>
  </div>
</template>

<style scoped>
/* navigation bar */
a {
  color: hsl(145, 63%, 63%);
}

a:hover,
a:focus {
  color: hsl(145, 63%, 63%);
  background-color: #f5f5f5 !important;
}

#nav-container {
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.2);
  /* Change the color to light grey */
  width: 100%;
  background-color: #f5f5f5;
}

.navbar {
  max-width: 980px;
  margin-left: auto;
  margin-right: auto;
  padding: 0;
  height: 50px;
  width: 88%;
}

.navbar img {
  margin-left: -40px;
  margin-bottom: 0px;
  float: left;
  padding: 0;
}

.navbar-toggle {
  background-color: #f5f5f5;
  font-size: 30px;
  margin-top: 9px;
  margin-bottom: -30px;
  color: hsl(145, 63%, 63%);
}



.navbar-brand {
  margin-top: 11px;
  margin-bottom: -30px;
  padding-left: 0px;
  float: left;
  font-size: 42px;
  text-transform: uppercase;
  color: rgb(142, 175, 8);
}

.navbar-brand:hover,
.navbar-brand:focus {
  color: rgb(142, 175, 8);
}

.navbar-nav {
  margin-top: 12px;
  margin-bottom: -30px;
  float: right;
  font-size: 17px;
  font-weight: bold;
}

@media (max-width: 767px) {
  .navbar-nav {
    float: none;
    margin-top: 40px;
    margin-left: -29px;
    margin-bottom: 0px;
  }
}
</style>
