<template>
  <!-- contact page -->
  <div id="contact">
    <!-- contact detail section -->
    <div class="contact-section">
      <p>
        111 Mitchell Street,<br />
        Bendigo <br />
        Vic. 3550 <br />
        Australia
      </p>
      <a href="tel: +61393720645">Tel: (+61) 3 4416 3306</a>
      <br />
      <a href="mailto:info@agriview.com.au">info@agriview.com.au</a>
    </div>
    <!-- map section -->
    <div class="contact-section-map">
      <iframe id="map"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3196.2948684244902!2d144.28099321571423!3d-36.76349308244852!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6ad759929d58c047%3A0x6f0009ac1a2658eb!2s111%20Mitchell%20St%2C%20Bendigo%20VIC%203550!5e0!3m2!1sen!2sau!4v1660799845059!5m2!1sen!2sau"
        width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy"
        referrerpolicy="no-referrer-when-downgrade" aria-hidden="false">
      </iframe>
    </div>
  </div>
</template>

<style scoped>
/* contact */


a {
  color: hsl(145, 63%, 63%);
}

#contact {
  margin-top: 10px;
  padding-top: 25px;
  width: 88%;
  max-width: 980px;
  margin-left: auto;
  margin-right: auto;

}

/* contact detail */

.contact-section {
  padding-left: 0px;
  float: left;
  width: 25%;
  font-size: 20px;
}

/* map section */

.contact-section-map {
  position: relative;
  padding-top: 50%;
  display: block;
  float: left;
  width: 75%;

  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 6px;
  box-shadow: 0 0.125em 0.275em 0 rgba(0, 0, 0, 0.125);
}

/* map */

#map {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

/* contact page responsive design */

@media (max-width: 940px) {
  .contact-section {
    float: none;
    width: 100%;
    font-size: 20px;
  }

  .contact-section-map {
    float: none;
    width: 100%;
    padding-top: 100%;
  }
}
</style>
