import { createRouter, createWebHistory } from 'vue-router';
import VueHome from "../views/VueHome.vue";
import VueContact from "../views/VueContact.vue";
// import VueEditor from "../views/VueEditor.vue";
// import Admin from "../components/Editor/adminNews.vue";
// import EditNews from "../components/Editor/EditNews.vue";
//import ComposeNews from "../components/Editor/ComposeNews.vue";
import IndustryInsight from "../views/IndustryInsight.vue";

const routes = [
  {
    path: "/",
    component: VueHome
  },
  {
    path: "/contact",
    component: VueContact
  },
  {
    path: "/industry_insight",
    component: IndustryInsight
  },
  // {
  //   path: "/admin/news/:securityKey",
  //   component: VueEditor,
  //   props: true,
  //   children: [
  //     {
  //       path: "",
  //       component: Admin
  //     },
  // {
  //   path: "edit/:id",
  //   component: EditNews,
  //   props: true
  // },
  // {
  //   path: "compose",
  //   component: ComposeNews
  // }
  // ]
  // }
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

export default router;