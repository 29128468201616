<template>
  <div class="industry">
    <!-- header section -->
    <header id="header-section">
      <h1 id="header">Industry Insights</h1>
      <p id="sub-header">
        Welcome to Industry Insights this will be updated regularly.
      </p>
    </header>

    <!-- loading indicator -->
    <h3 class="load" v-if="loading">Loading...</h3>
    <h3 class="load" v-if="error">There was some error...</h3>

    <!-- news section -->
    <div class="newsList">
      <div class="news-content" v-for="news in newsList" :key="news.ID">
        <div class="new-img-container">
          <img class="news-img" :src="'/img/' + news.Img" alt="Placeholder" />
        </div>
        <div class="news-text">
          <b>
            <h4 v-html="news.Title"></h4>
          </b>
          <i class="fas fa-table"></i> {{ news.Date }}
          <i class="fas fa-pen"></i> {{ news.Author }}
          <span v-html="news.Content"></span>
          <a href="#" @click.prevent="showPopup(news)">Read More</a>
        </div>
      </div>
    </div>

    <!-- popup window -->
    <div class="popup" v-if="popupVisible">
      <div class="popup-content">
        <div class="popup-header">
          <b>
            <h4> <b> {{ popupNews.Title }}</b></h4>
          </b>
          <i class="fas fa-times" @click.prevent="hidePopup"></i>
        </div>
        <hr>
        <img :src="'/img/' + popupNews.Img" alt="Placeholder" />
        <span v-html="popupNews.ContentDetail"></span>
        <a href="#" @click.prevent="hidePopup">Close</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: true,
      error: false,
      newsList: [],
      popupVisible: false,
      popupNews: null,
    };
  },
  mounted() {
    this.fetchNews();
  },
  methods: {
    async fetchNews() {
      try {
        const response = await fetch('/news/list');
        const data = await response.json();
        this.newsList = data;
        this.loading = false;
      } catch (error) {
        console.error(error);
        this.error = true;
        this.loading = false;
      }
    },
    showPopup(news) {
      this.popupNews = news;
      this.popupVisible = true;
    },
    hidePopup() {
      this.popupVisible = false;
    },
  },
};
</script>

<style>
/* styles for popup window */
.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup-content {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  max-width: 80%;
  max-height: 80%;
  overflow: auto;
}

.popup-content img {
  width: 200px;
  height: 200px;
  margin: 40px;
  float: left;
}


.industry {
  margin-top: 35px;
  overflow: hidden;
  max-width: 980px;
  margin-left: auto;
  margin-right: auto;
  width: 88%;
}

/* header */
#header-section {
  margin: 34px auto 0;
  text-align: center;
}

#header {
  margin: 0;
  padding-bottom: 8px;
  letter-spacing: -3px;
  display: inline-block;
  position: relative;
}

#header:before,
#header:after {
  content: "";
  position: absolute;
  top: 50%;
  height: 1px;
  width: 275%;
  display: block;
  background-color: rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 0 0 rgba(255, 255, 255, 0.795);
}

#header:before {
  right: 100%;
  margin-right: 0.5em;
}

#header:after {
  left: 100%;
  margin-left: 0.5em;
}

#sub-header {
  font-size: 24px;
  letter-spacing: -1px;
}

@media (max-width: 767px) {
  #industry-header {
    font-size: 42px;
  }

  #industry-sub-header {
    font-size: 18px;
  }
}

/* loading section */
.load {
  text-align: center;
}

/* list of news */
.news-content {
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  box-shadow: 0 0.125em 0.275em 0 rgba(0, 0, 0, 0.125);
  overflow: hidden;
  float: left;
  margin: 1.5%;
  width: 30.3333333%;
}

.news-img {
  width: 100%;
  height: auto;
}

.news-text {
  padding: 1.5em;
  background-color: #fff;
  height: 350px;
}

@media (max-width: 1199px) {
  .news-content {
    width: 46.3333333%;
  }
}

@media (max-width: 660px) {
  .news-content {
    float: none;
    width: 88%;
    margin-bottom: 45px;
    margin-left: auto;
    margin-right: auto;
  }

  .news-text {
    height: auto;
  }
}

/* news detail dialog box  */
.modal-dialog {
  width: 61%;
}

.modal-image {
  width: 200px;
  height: 200px;
  margin: 40px;
  float: left;
}

.modal-body {
  min-height: 600px;
}

@media (max-width: 767px) {
  .modal-dialog {
    margin-left: auto;
    margin-right: auto;
    width: 90%;
  }
}

@media (max-width: 440px) {
  .modal-image {
    width: 100%;
    height: 100%;
    margin: 0;
    text-align: center;
    float: none;
  }
}

.popup-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
}

.popup-header h2 {
  margin: 0;
}

.popup-header i {
  cursor: pointer;
  font-size: 1.5rem;
  color: #333;
}

.popup-header i:hover {
  color: #f00;
}

.popup-content {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  max-width: 800px;
  max-height: 80%;
  overflow-y: auto;
  background-color: #fff;
  border: 1px solid #333;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  z-index: 9999;
}
</style>