<template>
  <div id="logo">
    <img src="@/assets/AgriViewKynetec.jpg" alt="logo" width="700" height="140" />
    <h4>
      <b>
        Agriview is now part of Kynetec, the global leader in agricultural and animal
        health data analytics and insights.
      </b>
    </h4>
  </div>
</template>
<style scoped>
#logo {
  text-align: center;

  margin-top: 100px;
  margin-bottom: 20px;

  max-width: 980px;
  margin-left: auto;
  margin-right: auto;
  width: 88%;

  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  box-shadow: 0 0.125em 0.275em 0 rgba(0, 0, 0, 0.125);
}
</style>